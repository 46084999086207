import react, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import QRCode from 'react-qr-code';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import _ from 'lodash'
import Button from 'react-bootstrap/Button';

const QrDisplay = ({qrVal, textVal,indexId}) => {
    return (
        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} id={indexId}>
            <Row>
                <Col>
                    <p className="text-strong text-glow-neon" style={{fontSize:'2rem'}}>
                        {textVal}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <QRCode value={qrVal} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            
        </Col>
    )   
}


const FormQr = ({setTextValue,setFColor,setBgColor}) => {
    return (
                <Container>
                    <Form>
                        <Row>
                        <Col>
                        <Form.Row>
                            <Form.Label for="textVal">Website URL</Form.Label>
                            <Form.Control type="text" id="textVal1" defaultValue="https://www.johnlevengood.com" />
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="textVal2">Source</Form.Label>
                            <Form.Control type="text" id="textVal2" />
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="textVal3">Medium</Form.Label>
                            <Form.Control type="text" id="textVal3"/>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="textVal4">Campaign</Form.Label>
                            <Form.Control type="text" id="textVal4" />
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="textVal5">Content</Form.Label>
                            <Form.Control type="text" id="textVal5" />
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="textVal6">Background Color</Form.Label>
                            <Form.Control type="text" id="textVal6" defaultValue="#ffffff"/>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="textVal7">Foreground Color</Form.Label>
                            <Form.Control type="text" id="textVal7" defaultValue="#000000"/>
                        </Form.Row>
                        
                        </Col>
                        </Row>
                        <Button
                            onClick={() => {
                                const textValInput = document.getElementById('textVal1')
                                const textValInput2 = document.getElementById('textVal2')
                                const textValInput3 = document.getElementById('textVal3')
                                const textValInput4 = document.getElementById('textVal4')
                                const textValInput5 = document.getElementById('textVal5')
                                const textValInput6 = document.getElementById('textVal6')
                                const textValInput7 = document.getElementById('textVal7')
                                
                                setTextValue(`${textValInput.value}?utm_source=${textValInput2.value}&utm_medium=${textValInput3.value}&utm_campaign=${textValInput4.value}&utm_content=${textValInput5.value}`)
                                setFColor(textValInput7.value)
                                setBgColor(textValInput6.value)
                            }}
                        >
                            Save
                        </Button>
                        {/* <Button id="closeForm" onClick={() => setFormVisible(false)}>Close</Button> */}
                    </Form>
                </Container>
    )
}


const QrCodePage = () => {
    const [textValue, setTextValue] = useState('')
    const [bgColor, setBgColor] = useState('#ffffff')
    const [fColor, setFColor] = useState('#000000')


    return (
        <Row style={{height:'99vh'}} className="text-white text-center p-5">
            <Col >
                <Row>
                    <Col lg={4} md={4} xl={4} sm={12} xs={12}>
                        <FormQr setTextValue={setTextValue} setBgColor={setBgColor} setFColor={setFColor} />
                    </Col>
                    <Col lg={8} md={8} xl={8} sm={12} xs={12}>
                        <QRCode value={textValue} size="500" bgColor={bgColor} fgColor={fColor} />
                    </Col>
                </Row>
                <Row>
                    <Col><p className="big-words"></p></Col>
                </Row>
            </Col>
        </Row>
        
    )
}

export default QrCodePage