import react, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import QRCode from 'react-qr-code';
import Card from 'react-bootstrap/Card';
import qs from 'query-string'
import Form from 'react-bootstrap/Form'
import _ from 'lodash'
import Button from 'react-bootstrap/Button';

const QrDisplay = ({qrVal, textVal,indexId}) => {
    return (
        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} id={indexId}>
            <Row>
                <Col>
                    <p className="text-strong text-glow-neon" style={{fontSize:'2rem'}}>
                        {textVal}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <QRCode value={qrVal} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            
        </Col>
    )   
}

const displayQrItems = (items) => {
    // console.log(items)
    return _.map(items, (item,index) => {
        return <QrDisplay qrVal={item.qrVal} textVal={item.textVal} indexId={"qr" + index}/>
    })
}

const FormQr = ({setQrItems,qrItems,setFormVisible}) => {
    return (
                <Container>
                    <Form>
                        <Row>
                        <Col>
                        <Form.Row>
                            <Form.Label for="textVal">VENMO HANDLE</Form.Label>
                            <Form.Control type="text" id="textVal1" />
                        </Form.Row>
                        
                        </Col>
                        <Col>
                        <Form.Row>
                            <Form.Label for="textVal">CASHAPP HANDLE</Form.Label>
                            <Form.Control type="text" id="textVal2" />
                        </Form.Row>
                        
                        </Col>
                        </Row>
                        <Button
                            onClick={() => {
                                const textValInput = document.getElementById('textVal1')
                                const textValInput2 = document.getElementById('textVal2')
                                
                                
                                const qstr = {
                                    title: document.getElementById('titleValueInput').value,
                                    label: [textValInput.value,textValInput2.value],
                                    value: [`https://www.venmo.com/${textValInput.value}`,`https://cash.app/$${textValInput2.value}`],
                                    sublabel: ["Venmo", "Cashapp"]
                                }
                                window.open(`/qrcode?${qs.stringify(qstr)}`,'_blank')
                                textValInput.value = ''
                                textValInput2.value = ''
                                document.getElementById('titleValueInput').value = ''

                            }}
                        >
                            Save
                        </Button>
                        {/* <Button id="closeForm" onClick={() => setFormVisible(false)}>Close</Button> */}
                    </Form>
                </Container>
    )
}

const TitleForm = ({setTitleValue}) =>{
    return (
        <Col>
            <Form id="titleForPage">
                <Form.Row>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" id="titleValueInput" />
                    
                </Form.Row>
            </Form>
        </Col>

    )
}

const QrCodePage = () => {
    const [qrItems, setQrItems] = useState([])
    const [textValue, setTextValue] = useState(null)
    const [titleValue, setTitleValue] = useState('')
    const [formVisible,setFormVisible] = useState(true)
    console.log(qrItems)
    return (
        <Row style={{height:'99vh'}} className="text-white text-center p-5">
            <Col >
                {(titleValue === '' ? <TitleForm setTitleValue={setTitleValue}/> : <Row><Col className="p-5"><h1 className="text-glow-pink text-strong bigger-words">{titleValue}</h1></Col></Row> )}

                <Row xs={1} sm={1} md={1} lg={1} xl={1}>
                    {displayQrItems(qrItems)}
                    {(formVisible) ? <FormQr setQrItems={setQrItems} qrItems={qrItems} setFormVisible={setFormVisible}/> : null}
                </Row>
                <Row>
                    <Col><p className="big-words"></p></Col>
                </Row>
            </Col>
        </Row>
        
    )
}

export default QrCodePage