import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import KJ from '../Music/KJ'


const MusicBanner = (props) => {
    const [showModal, setShowModal] = useState(false)
    
    return (
        <>
        <Container style={{height:'20vh', display:'flex', flexDirection:'column', justifyContent:'center', boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.5)'}} id="backgroundKaraokeBanner" bg="dark" onClick={() => {
            setShowModal(true)
        }} >
            
            <Row style={{}}>
                <Col className="text-center text-strong text-glow-pink ">
                    <span className="text-bg-black text-center m-2">Support Queer Music</span>
                </Col>
                <Col className="text-center">
                    <Button size="lg">Listen Now</Button>
                </Col> 
                
                
            </Row>
            
                    
        </Container>
        <Modal
            id="promoModal"
            centered="true"
            show={showModal}
            onHide={() => {setShowModal(false)}}
            scrollable
        >
            <Modal.Header closeButton>
                <h2>Support a Local Artist</h2>
            </Modal.Header>
            <Modal.Body id="promoBody" style={{maxHeight:'75vh'}}>
                <KJ />
            </Modal.Body>
        </Modal>
        </>
    )
}

export default MusicBanner