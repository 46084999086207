import React from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/Image'

const EmailSignUp = () => {
    return (
        <Col className="p-4" >
            <Container className="bg-dark-j-faded p-3" style={{borderRadius:'5px'}}>
                <Row>
                    <Col>
                    <p className="h2">Merch</p>
                    <p>Show your pride and Say Gay everywhere you go!</p>
                    <a href="https://johnlevengood.myshopify.com/" target="_blank" rel="noopener noreferrer"><Button size="lg">Shop</Button></a>
                    </Col>
                    <Col>
                        <Image src="https://cdn.shopify.com/s/files/1/0680/7742/0861/products/unisex-heavy-blend-hoodie-black-front-63837f925ac90.jpg?v=1669562273&width=600" fluid />
                    </Col>
                </Row>
            </Container>
        </Col>
    )
}

export default EmailSignUp