import React, { Component } from 'react'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'
//import * as actions from '../actions'
import {
    CSSTransition,
    TransitionGroup
} from 'react-transition-group';
import '../index.css'
import Landing from './Landing/Landing'
import GoogleAnalytics from './Marketing/GoogleAnalytics'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Music from './Music/Music'
import Song from './Music/Song'
import Family from './Family/Family'
import Karaoke from './Karaoke/Karaoke'
import TermsOfUse from './Terms/TermsOfUse'
import KaraokeQR from './Karaoke/KaraokeQR';
import Links from './Music/Links'
import About from './About/About'
import QrCodePage from './Karaoke/QrCodePage';
import QrCodeParams from './Karaoke/QrCodeParams';
import CustomQR from './Music/CustomQR';
import Booking from './Music/Booking';
import ShowPage from './Music/ShowPage';
import SecretPreview from './Music/SecretPreview';
import LinkPage from './Marketing/LinkPage';

class App extends Component {

    render() {
        return (
                <BrowserRouter>
                   
                    {/* <Promo /> */}
                    <TermsOfUse />
                    
                    <Route render={({location}) => (

                            // <TransitionGroup id="transition" style={{display:'flex', justifyContent:'center'}}>
                            // <CSSTransition
                            //     key={location.key}
                            //     timeout={1000}
                            //     classNames="fade"
                            // >
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <Row style={{width:'100vw', paddingLeft:'0px',paddingRight:'0px'}}>
                                <Col style={{height:'100vh',width:'100vw',overflowY:'auto', overflowX:'hidden', padding:'0px'}}>
                                    <Switch location={location}>
                                        
                                        <Route exact path="/film" component={Family} />
                                        <Route exact path="/music/:song" component={Song} />
                                        <Route exact path="/kj" component={Karaoke} />
                                        <Route exact path="/karaoke" component={Karaoke} />
                                        <Route exact path="/karaoke/:kjName" component={Karaoke} />
                                        <Route exact path="/karaokeqr" component={KaraokeQR} />
                                        <Route exact path="/music" component={Links} />
                                        <Route exact path="/about" component={About} />
                                        <Route exact path="/qrcode" component={QrCodeParams} />
                                        <Route exact path="/qrcode/entry" component={QrCodePage} />
                                        <Route exact path="/shows" component={ShowPage} />
                                        <Route exact path="/qrcode/custom" component={CustomQR} />
                                        <Route exact path="/booking" component={Booking} />
                                        <Route exact path="/links" component={LinkPage} />
                                        <Route exact path="/links/:category" component={LinkPage} />
                                        <Route exact path="/links/:category/:stateprov" component={LinkPage} />
                                        <Route exact path="/exclusive/werkethic" component={SecretPreview} />
                                        <Route path="/" component={Landing} />
                                    </Switch>
                                </Col>
                                </Row>
                            </div>
                            // </CSSTransition>
                            // </TransitionGroup>

                            
                    )}/>
                    <GoogleAnalytics />
                </BrowserRouter>
        )
    }
}

export default App