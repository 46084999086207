import React from 'react'
import Carousel from 'react-bootstrap/Carousel'

const photos = [
    {
        url: "https://d2yuda.bn.files.1drv.com/y4m2JKsN7pB67IIWWNefvsy59SMxlbKgRaUVe3zJvaVEA9T5YAZRNguiSijVlViTkTCsKCqpuWcuSFx4s9CFGPnTat4wJ4DplWi-JHkzrUiHQQritMmZhzBlp1WEHO2LN8FL4IFWhyUwk9JbhaTdXSUD8uVr85OSPaUpSmAcMvzPd7mG4bgtjG5gyAW3-RNhJ77L79JN_lxf370TJvYNMVReA?width=5184&height=3456&cropmode=none",
        caption: "Bella, Savannah, John, Hailey, Alyssa, Aaron"
    },
    {
        url:"https://dwyuda.bn.files.1drv.com/y4m6EN2oOn5zmix2tq0ZGPQirVnlbCoQb0p-gVeFbJeEc_0t5NHQdDPKVQgtwDsenU0gSua87U5Bg0I6SFFaXwrH_X2Xc_ofmt126Ftbm1_Y7lPfkSYQ2LnwpSEwHO3_AuHmTHJZYCNvl_TVJMC4KTCPeNJ71US_-YVJXjUXaS2rhNAAYUZ9wHonRoKroGwVFf2YeT_bwLx03xDuU2O390-3w?width=5184&height=3456&cropmode=none",
        caption:"John & Savannah"
    },
    {
        url: "https://pct8ma.bn.files.1drv.com/y4mghKlXl9XTdbZCWDh7WHwvEPme-Sw65sFG9ZMrasroNC_0_u-ijVNRQtOPyhAvqiiynxUWIospMYvS3VusfVQNQfwMDTBtmRfkXgVhmMxQIPO8UUXEt9wQpdmE0ifcYr5b5AvNoDyVNVbPj2c1bME68-BHD8RFUwfFJyu0GfXpJhM0y76PYaibIeBWZRk25kz_4Zb2FF_gAA678Hwt4dYiw?width=5184&height=3456&cropmode=none",
        caption:"The Levengoods"
    },
    {
        url: "https://7czyiq.bn.files.1drv.com/y4mfi1hI2UgP-_AqPsicKqWiaETRxS_MGuxVSKPXPIckqUWIIDOmhLiqPurC0UqrIQ7T02LrnJmN0HudEoVrUKS07nwCLfMYmbiMBx0xC6D4HHiXs5nKdFeei3BsUns5ZL8-0hRTrPLUeMkrQ34y7MLJbsgLc59HxlIPE-kN3GfmzAVM4mKZoZ7WL3_vfeSAPrwzhzj2hJaLjyL1EJUSi8zRw?width=2048&height=1365&cropmode=none",
        caption:"Savannah, Sandy, John, Jim"
    },
    {
        url: "https://78zyiq.bn.files.1drv.com/y4moIMoMWIaCqHittGPXoJQOdpCl_Ew44AjJR4Q7WH40ZighNvfinGks9atdmNvvjYRSfZDZHcu4RUqFQ3qhN0Vk1ovoUVH4klVmUeSG7DOeErDCxJxQtCc1Ag9LNaLFXYDIVXDWEtLRKHYnaku3uLne2gNSKEXa8rc7S7U_xegChaU-O8dz1gh92XD0zVfqog61eNxWlMszNdUQRwE7HZUGw?width=2048&height=1365&cropmode=none",
        caption:"John, Sandy, Jim, Savannah"
    }
]

const picItems = () => {
    return (
        photos.map((pic, index) => {
            return (
            <Carousel.Item key={index}>
                <img src={pic.url} className="d-block w-100" alt="Levengood family group" style={{ width: '100%' }} />
                <Carousel.Caption>
                    <h3>{pic.caption}</h3>
                </Carousel.Caption>
            </Carousel.Item>
            )
        })
    )
}

const FamilyCarousel = (props) => {
    return (
        <Carousel id="family-carousel">
            {picItems()}
        </Carousel>
    )
}

export default FamilyCarousel