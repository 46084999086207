import React from 'react'
import ReactPlayer from 'react-player/youtube'
import songs from '../Music/songs'
import _ from 'lodash'

const YouTubePlayer = () => {
    let youTubeURLs = _.map(songs, (thisSong,index) => {
        
            return thisSong.youtube
        
        
    })
    return <ReactPlayer 
        url={'https://youtube.com/playlist?list=PLEsmJ45uILbWjpwZxzxBSalcY0zDc9JSG&si=zhu0Y4V3XFdnmAbS'}
        controls={true}
        className="mt-1"
        width="100%"
        height="100%"
    />
}

export default YouTubePlayer