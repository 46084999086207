import react, {useEffect, useState} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import * as dayjs from 'dayjs'

import { fetchGigs } from '../../actions'
import _ from 'lodash'
import BookingButton from './BookingButton'

const GigCard = ({gig,upcomingShow}) => {
    return (
        <Col className="pt-2 pb-2" >
            <Card bg={(upcomingShow ? "light" : "dark")} text={(upcomingShow ? "dark" : "light")}>
                <Card.Body className="text-center">
                    <Card.Subtitle style={{textTransform:'uppercase', fontFamily: 'Arial', textAlign:'center'}}><strong>{dayjs(gig.datetime).format(`ddd, MMM D, YYYY ${(upcomingShow ? "h:mm A" : '')}`)}</strong></Card.Subtitle>
                    <Card.Title>{gig.venue.name}</Card.Title>
                    <Row>
                        <Col><Card.Text style={{textAlign:'center'}}>{`${gig.venue.city}, ${gig.venue.region}`}</Card.Text></Col>
                        {(upcomingShow ? <Col className="text-center"><Button size="sm" href={gig.url} rel="noopener noreferrer" target="_blank">Get Info</Button></Col> : null)}
                    </Row>
                    
                </Card.Body>
            </Card>
        </Col>
    )
}

const renderGigsUpcoming = (gigs) => {
    return _.map(gigs, (gig, index) => {
        return <GigCard gig={gig} key={index} upcomingShow={true}/>
    })
}

const renderGigsPast = (gigs) => {
    return _.map(gigs, (gig, index) => {
        return <GigCard gig={gig} key={index} upcomingShow={false}/>
    })
}


const Shows = ({fetchGigs,dispPastShows}) => {
    const [showData, setShowData] = useState([])
    const [pastShows, setPastShows] = useState([])
    useEffect(() => {
        fetchGigs({upcoming: true},setShowData)
        fetchGigs({upcoming:false}, setPastShows)
    },[])
    return (
        <Row>
            <Col>
            <Row>
                <Col>
                {dispPastShows ? <h1 className="text-glow-pink text-strong">Upcoming Shows</h1> : null }
                    <Row lg={3} md={3} sm={1} xs={1}>
                        {renderGigsUpcoming(showData)}

                    </Row>
                </Col>
            </Row>
            <hr />
            { dispPastShows ? 
                <Row>
                    <Col>
                        <h2 className="text-glow-pink text-strong">Past Performances</h2>
                        <Row lg={3} md={3} sm={1} xs={1}>
                            {renderGigsPast(pastShows)}
                        </Row>
                    </Col>
                </Row> : 
                null
            }
                <Row className="mb-3">
                    <Col className="text-center text-white">
                        <h3>Book John today for an unforgettable performance your next event.</h3>
                        <Button size="lg">
                            Booking
                        </Button>
                    </Col>
                </Row>
            
            </Col>
        </Row>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps
    }
}

export default connect(mapStateToProps, {fetchGigs})(Shows)