import _ from 'lodash'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/esm/Row'
import ReactGa from 'react-ga4'


const renderKJs = (kjs, tipKJ, setTipKJ) => {
    return _.map(_.values(kjs), (thisKJ) => {
        if(!tipKJ){
            return (
                <Row className="mt-1 mb-1" key={thisKJ._id}>
                    <Col lg={12} >
                        <Button 
                            size="lg" 
                            block variant="dark" 
                            className="text-strong"
                            hidden={tipKJ}
                            onClick={()=> {
                                setTipKJ(thisKJ.name)
                            }}
                        >{thisKJ.name}</Button>
                    </Col>
                </Row>
            )
        }
        return null
        })
}

const venmoURL = (kjvenmo) => {
    var OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows")!= -1) OSName="Windows";
    if (window.navigator.userAgent.indexOf("Mac")            != -1) OSName="Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11")            != -1) OSName="UNIX";
    if (window.navigator.userAgent.indexOf("Linux")          != -1) OSName="Linux";

    switch (OSName) {
        case "Mac/iOS":
            return `venmo://paycharge?txn=pay&recipients=${kjvenmo}`
        case "Linux":
            return `intent://paycharge?txn=pay&recipients=${kjvenmo}#Intent;package=com.venmo;scheme=venmo;end`
        case "UNIX":
            return `https://www.venmo.com/${kjvenmo}`
        default:
            return `https://www.venmo.com/${kjvenmo}`
    }
    
}

const renderLinks = ({links, name}) =>{
    
    return (
        <Row>
            {links.venmo && 
            <Col className="pb-3">
                    <a id="venmo" href={venmoURL(links.venmo)} target="_blank" rel="noopener noreferrer">
                        <Button size="lg" block variant="dark">Venmo</Button>
                    </a>
                    <p>@{links.venmo}</p>
            </Col>
            }
            {links.cashapp && 
            <Col className="pb-3">

                    <a id="cashapp" href={`https://cash.app/$${links.cashapp}`} target="_blank" rel="noopener noreferrer">
                        <Button size="lg" block variant="dark">Ca$hApp</Button>
                    </a>
                    <p>${links.cashapp}</p>
                
            </Col>
            }
        </Row>
    )
        
}

const KJHosts = ({kjs,selectedKJ}) => {
        const [tipKJ, setTipKJ] = useState(selectedKJ)
        const selected = kjs[tipKJ]
        console.log(tipKJ + ' - ' + selected)
        return (
            <>
                {!selected && renderKJs(kjs, tipKJ, setTipKJ)}
                {selected &&
                    <Row>
                        <Col>
                        <h2 className="h1 text-strong text-center">{selected.name}</h2>
                        </Col>
                    </Row>
                }
                {selected && 
                    renderLinks(kjs[tipKJ])
                }
            </>
        )
        
}

export default KJHosts