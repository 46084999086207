import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGa from 'react-ga4'

class GoogleAnalytics extends React.Component {

    render () {
        ReactGa.send({hitType: 'pageview', page: window.location.pathname + window.location.search})
        return null;
    }
}

export default withRouter(GoogleAnalytics);