import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import FeaturedAlbum from '../../assets/img/albumart/saygaysmall.jpg'
import FeaturedAlbum2 from '../../assets/img/albumart/rightnowsmall.jpg'
import FeaturedAlbum3 from '../../assets/img/albumart/werkethicalbumart.jpg'
import Header from './Header'
import _ from 'lodash'
import MusicListenButton from '../Music/MusicListenButton'
import Footer from './Footer'
import SayGayCutout from '../../assets/img/Say Gay Cutout small.png'
import Merch from '../Marketing/Merch'
import YouTubePlayer from '../Marketing/YouTubePlayer'
import Shows from '../Music/Shows'
import logo from '../../assets/svg/johnlevengoodlogo.svg'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'

const carouselItems = (words) => {
    
    return _.map(words, (word, index) => {
        return (
            <Carousel.Item key={index} interval={2000} style={{height:'500px', width:'500px'}} >
                <div style={{width:'100%', height:'100%'}} className="d-flex justify-content-center align-items-center">
                    <div>
                        <h1 style={{fontSize:'4rem'}}>{word}</h1>
                    </div>
                    
                </div>
            </Carousel.Item>
        )
    })
}

const Landing = (props) => {
    return (
        <>
        
        <Row style={{height:'90vh'}} id="landingContainerImage">
            <Container>

            <Col style={{height:'100%'}} className="d-flex justify-content-center align-items-center">
                {/* <Carousel slide={true} pause={false} controls={false} indicators={false} style={{width:'500px'}}>
                    {carouselItems(['Vision', 'Art', 'Innovation','Humor','Levengood'])}
                </Carousel> */}
                
                
                <div id="landingNameContainer" className="d-flex justify-content-center align-items-center" style={{width: '100vw'}}>
                    <Image id="landingName" fluid src={logo} alt="john levengood logo"/>
                    <Button size="lg" href="#signup">Get 20% Off Merch</Button>
                </div>
                
            </Col>
            </Container>
            
        </Row>
        <Header />
        
        {/* <Row id="music" className="bg-emph landing-section-header" >
            <Container >
                <Row lg={2} md={2} sm={1} xs={1}>
                    <Col className="d-flex justify-content-center align-items-center">
                        <h2 style={{fontSize:'6rem', textTransform:'uppercase'}} className="">Music</h2>
                    </Col>
                    <Col>
                    
                    </Col>
                </Row>
            </Container>
        </Row> */}
        <Row className="landing-section-body bg-emph pb-3" style={{minHeight:'50vh'}}>
            <Container >
                
                <Row lg={2} md={2} sm={1} xs={1} className="d-flex justify-content-center align-items-center">
                    <Col lg={8}>
                        <Row >
                            <Merch />
                        </Row>
                        <Row className="d-flex justify-content-center align-items-center">
                                <a
                                    id="instagram"
                                    href='https://instagram.com/johnlevengood'
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">Instagram</Button>
                                </a>
                            </Row>
                            <Row className="d-flex justify-content-center align-items-center">
                                <a
                                    id="tiktok"
                                    href='https://www.tiktok.com/@johnlevengood'
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">TikTok</Button>
                                </a>
                            </Row>
                    </Col>
                    <Col id="musicLinks" lg={4}>
                        <Row lg={1} sm={1} className="p-4">
                            <Col className="d-flex justify-content-center align-items-center">
                                <MusicListenButton site='apple' />
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <MusicListenButton site="spotify" />
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <MusicListenButton site="youtube" />
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <MusicListenButton site="pandora" />
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <MusicListenButton site="amazon" />
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <MusicListenButton site="tidal" />
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row lg={3} md={3} sm={3} xs={3} className="p-3">
                        <Col>
                            <Image src={FeaturedAlbum3} fluid className=""/>
                        </Col>
                        <Col>
                            <Image src={FeaturedAlbum} fluid className=""/>
                        </Col>
                        <Col>
                            <Image src={FeaturedAlbum2} fluid className=""/>
                        </Col>
                    </Row>
            
            </Container>
        </Row>
        <Row className="p-2 bg-light">
            <Col></Col>
            <Col lg={8} md={8} sm={12} xs={12} style={{minHeight:"50vh" }}>
                <YouTubePlayer />
            </Col>
            <Col>
            </Col>
        </Row>
        <Row id="signup" className="bg-emph text-light landing-section-body">
            <Container>
                <Col>
                    <Row className="mt-4">
                        <iframe src="https://cdn.forms-content.sg-form.com/440bce2c-0156-11ee-86bd-ae406b9a6fa9" width="100%" height="600px" style={{border:"none",overflowY:'hidden'}}/>
                    </Row>
                </Col>

                </Container>
        </Row>
        <Row id="shows" className="bg-dark-j text-light landing-section-header" >
            <Container >
                <Row lg={2} md={2} sm={1} xs={1} style={{height:"100%"}}>
                    <Col className="d-flex justify-content-center align-items-center">
                        <h2 style={{fontSize:'6rem', textTransform:'uppercase'}} className="">Shows</h2>
                        
                    </Col>

                </Row>
            </Container>
            
        </Row>
        <Row className="">
            <Col>
            <Container>
                <Shows />
            </Container>
            </Col>
            
        </Row>

        <Footer />
        </>
    )
}

export default Landing