import {
    TOGGLE_PROMO, TOGGLE_PRIVACY,
} from '../actions/types'

export default function (state = { promo: true, priv: false }, action) {
    switch (action.type) {
        case TOGGLE_PROMO: 
            return {
                ...state,
                promo: action.payload
            }
        case TOGGLE_PRIVACY:
            return {
                ...state,
                priv: action.payload
            }
        default: 
            return state
    }
}