import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MusicListenButton from './MusicListenButton'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'
import ReactGa from 'react-ga4'

const Music = ({kjs}) => {
    
    return (
        <>
            <Row >
                <Col lg={4} md={6} sm={2} style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}}>
                    <h4>Links</h4>
                    
                    <MusicListenButton site="apple"/>
                    <MusicListenButton site="spotify"/>
                    <MusicListenButton site="pandora" />
                    <MusicListenButton site="amazon" />
                    <MusicListenButton site="tidal" />
                    <MusicListenButton site="youtube" />
                    <LinkContainer to="/amazon"><Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">Fashion</Button></LinkContainer>
                    <a
                        id="instagram"
                        href='https://instagram.com/johnlevengood'
                        target="_blank" rel="noopener noreferrer"
                    >
                        <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">Instagram</Button>
                    </a>
                    <a
                        id="tiktok"
                        href='https://www.tiktok.com/@johnlevengood'
                        target="_blank" rel="noopener noreferrer"
                    >
                        <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">TikTok</Button>
                    </a>
                </Col>
            </Row>
        </>
        
    )
}

export default Music