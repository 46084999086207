import react, {useEffect, useState} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Header from '../Landing/Header'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ReactPlayer from 'react-player/youtube'
import BookingButton from './BookingButton'
import Shows from './Shows'

const ShowPage = () => {
    return (
        <>
        <Header />
        <Container>
            <Row className="mt-3">
                <Col className="text-center">
                    <BookingButton propColor="dark" />
                    <p>info@johnlevengood.com</p>
                </Col>
            </Row>
            <Col style={{display:'flex',justifyContent:'center'}}>
                <ReactPlayer 
                    url={'https://youtube.com/playlist?list=PLEsmJ45uILbWjpwZxzxBSalcY0zDc9JSG&si=zhu0Y4V3XFdnmAbS'}
                    controls="true"
                    className="mt-1"
                    width="100%"
                    height="65vh"
                />
                
            </Col>
            <Shows dispPastShows={true} />
            
        </Container>

        </>
    )
}

export default ShowPage