import React, { useState } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import { LinkContainer } from 'react-router-bootstrap'
import ReactGa from 'react-ga4'
import Container from 'react-bootstrap/Container'
import HeaderNav from './Header-Nav'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactPlayer from 'react-player/youtube'
import songs from '../Music/songs'
import _ from 'lodash'
import logo from '../../assets/svg/johnlevengoodlogo.svg'
import Image from 'react-bootstrap/Image'

const Header = (props) => {
    const [showMenu, setShowMenu] = useState(false)
    const randomNum = Math.floor(_.random(songs.length - 1))
    const song = songs[randomNum]
    return (
        
        <Navbar collapseOnSelect="true" expand="lg" sticky="top" className="bg-dark-j">
            <Container >
            <LinkContainer to="/"><Navbar.Brand><Image src={logo} style={{width:'100px'}}/></Navbar.Brand></LinkContainer>
                <Button 
                    variant="outline-light"
                    onClick={() => {
                        setShowMenu(true)
                    }}
                    id="navMenuButton"
                >Menu</Button>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <HeaderNav />
                </Navbar.Collapse>
            </Container>
                <Modal show={showMenu}
                    onHide={() => {
                        setShowMenu(false)
                    }}
                    dialogClassName="m-0 modal-dialog-custom"
                    scrollable={true}
                    centered={true}
                    style={{width:'100vw'}}
                >
                    <Modal.Body style={{height:'99vh',width:'100%'}} className="bg-dark-j text-white mobile-nav-text text-center">
                        <div className="text-right">
                        <Button 
                            onClick={()=>setShowMenu(false)}
                            variant="outline-light"
                        >
                            Close
                        </Button>
                        </div>
                        <div style={{borderBottom:'1px solid white'}}>
                        <Modal.Title>Menu</Modal.Title>
                        </div>
                        <HeaderNav setShowMenu={setShowMenu}/>
                        {/* <ReactPlayer 
                            url={song.youtube}
                            width="100%"
                            height="200px"
                            className="mt-3 mb-3"
                        /> */}
                    </Modal.Body>
                </Modal>
        </Navbar>
    )
}

export default Header