import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import KJ from './KJ'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'
import ReactPlayer from 'react-player/youtube'
import Container from 'react-bootstrap/Container'
import ReactGa from 'react-ga4'

import songs from './songs'
import _ from 'lodash'

const Links = (props) => {
    const randomNum = Math.floor(_.random(songs.length - 1))
    const song = songs[randomNum]
    return (
        <>
        <Header />
        <Row fluid="true" style={{ minHeight:'83vh' }}>
            <Col lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center' }}>
                
                <KJ />
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent:'space-around', alignItems: 'center'}}>
                <ReactPlayer 
                    url={song.youtube}
                    width="75%"
                    className="mt-3 mb-3"
                />
            </Col>
        </Row>
        <Footer />
        </>
    )
}

export default Links