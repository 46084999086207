import react from 'react'

import ReactGa from 'react-ga4'
import Button from 'react-bootstrap/Button'

const BookingButton = ({subject,propSize,propColor}) => {
    return (
        <a id="bookingEmail" href="mailto:info@johnlevengood.com" target="_blank" rel="noopener noreferrer">
        <Button size={ propSize || 'lg'} variant={propColor || "outline-dark"} className="text-glow-pink text-light">
            Booking Inquiries
        </Button>
        </a>
    )
}

export default BookingButton