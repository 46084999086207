const sites = [
    {
        url:'https://open.spotify.com/artist/64tEylbYN3xfXlxne6d78A?si=yFCSZf6oRZudZt56KP5pWA',
        name:'Spotify'
    },
    {
        url:'https://music.apple.com/us/artist/john-levengood/542969359?itsct=music_box&itscg=30200&app=music&ls=1',
        name:'Apple'
    },
    {
        url:'https://pandora.app.link/0xmVVuulP8',
        name:'Pandora'
    },
    {
        url:'https://music.amazon.com/artists/B008JPPD82?ref=dm_sh_314d-ab33-dmcp-d86d-13060&musicTerritory=US&marketplaceId=ATVPDKIKX0DER',
        name:'Amazon'
    },
    {
        url:'https://listen.tidal.com/artist/5688826',
        name:'Tidal'
    },
    {
        url:'https://music.youtube.com/channel/UCu35Mt3SQ-ou7S9NB0BnAjg',
        name:'YouTube'
    }
]

export default sites