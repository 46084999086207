import axios from 'axios'
import qs from 'query-string'
import {
    TOGGLE_PROMO,
    TOGGLE_PRIVACY
} from './types'
import _ from 'lodash'

import ReactGa from 'react-ga4'

export const togglePromo = (show) => async (dispatch, getState) => {
    const promo = await getState().modal.promo
    let newState = !promo
    if (show) {
        newState = show
    }
    dispatch({ type:TOGGLE_PROMO, payload: (newState) })
}

export const togglePrivacy = (show) => async (dispatch, getState) => {
    const priv = await getState().modal.priv
    let newState = !priv
    if (show) {
        newState = show
    }
    dispatch({ type: TOGGLE_PROMO, payload: false })
    dispatch({ type:TOGGLE_PRIVACY, payload: (newState) })
}

export const analyticsSend = (label) => async (dispatch) => {
    console.log(label)
    ReactGa.event({
        category:'Music-YouTube',
        action: 'Play',
        label: label,
        value: 1
    })
}

export const fetchSongs = (searchTerms, setResults) => async dispatch => {
    try {
        const query = qs.stringify(searchTerms)
        let domain = 'https://api.johnlevengood.com'
        //let domain = 'http://localhost:5000'
        try {
            const results = await axios.get(domain + '/api/karaoke?'+query)
            setResults([...results.data.results])
        } catch(e) {
            console.log(e)
        }
    } catch (e) {

    }
}

export const fetchRandom = (setResults) => async dispatch => {
    try {
        let domain = 'https://api.johnlevengood.com'
        //let domain = 'http://localhost:5000'
        try {
            const results = await axios.get(domain + '/api/karaoke/random')
            setResults([...results.data.results])
        } catch(e) {
            console.log(e)
        }
    } catch (e) {

    }
}

export const fetchKJs = (setKJs) => async dispatch => {
    try {
        let domain = 'https://api.johnlevengood.com'
        //let domain = 'http://localhost:5000'
        try {
            const results = await axios.get(domain + '/api/kjs')
            //console.log(_.values(_.keyBy(results.data, "name")))
            setKJs(_.keyBy(results.data, 'name'))
            
        } catch(e) {
            console.log(e)
        }
    } catch (e) {

    }
}

export const fetchGigs = ({upcoming},setShowData) => async dispatch => {
    
    try {
        // console.log('fetch gigs')
        let dateStr
        if(upcoming === true){
            dateStr = 'upcoming'
        } else {
            dateStr = 'past'
        }
        try {
            const results = await axios.get(`https://rest.bandsintown.com/artists/John%20Levengood/events?app_id=9156d712ade84838239cc4cb239c0bfb&date=${dateStr}`)
            //console.log(results.data)
            setShowData(results.data)
        } catch (e) {
            console.log(e)
        }

    } catch (e) {
        console.log(e)
    }
}

export const fetchLinks = ({stateProv,category},setLinkData,setStrMessage,setLinkLoading) => async dispatch => {
    let domain = 'https://api.johnlevengood.com'
    //let domain = 'http://localhost:5000'

    try {
        const results = await axios.get(domain + '/api/links')
        setLinkLoading(false)
        setLinkData(results.data.value)
    } catch (e) {
        setLinkLoading(false)
        setStrMessage("Error try again")
    }

}

