import react, { useState } from 'react';
import qs from 'query-string';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import QRCode from 'react-qr-code';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import _ from 'lodash'
import Button from 'react-bootstrap/Button';

const QrDisplay = ({qrVal, textVal, subVal, indexId}) => {
    return (
        <Col style={{ display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} id={indexId}>
            <Row>
                <Col style={{lineHeight:'1.2rem'}}>
                    <p>
                        <span className="text-glow-pink" style={{fontSize:'2rem'}}>{textVal}</span>
                        <br/>
                        <span className="text-glow-neon text-strong">{subVal}</span>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card >
                        <Card.Body>
                            <QRCode value={qrVal} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            
        </Col>
    )   
}

const displayQrItems = (items) => {
    // console.log(items)
    return _.map(items, (item,index) => {
        return <QrDisplay qrVal={item.qrVal} textVal={item.textVal} subVal={item.subVal} indexId={"qr" + index}/>
    })
}

const FormQr = ({setQrItems,qrItems,setFormVisible}) => {
    return (
        <Row>
            <Col>
                <Container>
                    <Form>
                        <Form.Row>
                            <Form.Label for="textVal">Text Value</Form.Label>
                            <Form.Control type="text" id="textVal" />
                        </Form.Row>
                        <Form.Row>
                            <Form.Label for="qrVal">QR Value</Form.Label>
                            <Form.Control type="text" id="qrVal" />
                        </Form.Row>
                        <Button
                            onClick={() => {
                                const qrValInput = document.getElementById('qrVal')
                                const textValInput = document.getElementById('textVal')
                                const newItems = [...qrItems,{qrVal: qrValInput.value, textVal: textValInput.value}]
                                console.log(newItems)
                                setQrItems(newItems)
                                qrValInput.value = ''
                                textValInput.value = ''
                            }}
                        >
                            Save
                        </Button>
                        <Button id="closeForm" onClick={() => setFormVisible(false)}>Close</Button>
                    </Form>
                </Container>
            </Col>
        </Row>
    )
}

const TitleForm = ({setTitleValue}) =>{
    return (
        <Col>
            <Form id="titleForPage">
                <Form.Row>
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" id="titleValueInput" />
                    <Button id="titleSubmit" onClick={() => setTitleValue(document.getElementById('titleValueInput').value)}>Save</Button>
                </Form.Row>
            </Form>
        </Col>

    )
}

const QrCodeParams = ({location}) => {
    const search = location.search
    const qstrs = qs.parse(search)

    console.log(qstrs)

    const qrItems = _.map(qstrs.label,(q, index) => {
        return { 
            qrVal: qstrs.value[index], 
            textVal: qstrs.label[index], 
            subVal: (qstrs.sublabel ? qstrs.sublabel[index] : false)
        }
    })

    console.log(qrItems)
    
    return (
        <Row fluid style={{height:'99vh'}} className="text-white text-center">
            <Col >
                <Row><Col className="p-2"><h1 className="text-glow-pink text-strong">{qstrs.title}</h1></Col></Row>

                <Row xs={1} sm={1} md={2} lg={2} xl={2}>
                    {displayQrItems(qrItems)}
                </Row>
                <Row>
                    <Col><p className="text-glow-pink" style={{fontSize:'1.5rem'}}>Scan with phone camera</p></Col>
                </Row>
            </Col>
        </Row>
        
    )
}

export default QrCodeParams