import react, {useEffect, useState} from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import * as dayjs from 'dayjs'

import { fetchLinks } from '../../actions'
import _ from 'lodash'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import FormControl from 'react-bootstrap/FormControl'
import { LinkContainer } from 'react-router-bootstrap'
import CardColumns from 'react-bootstrap/CardColumns'

const renderLinks = ({linkData,category,userStateProv,linkCategory}) => {
    return _.map(_.filter(linkData,(thisIter) => {
        let cat = thisIter.category
        if (!!category && (cat.toLowerCase() === category.toLowerCase())){
            return thisIter
        }
        if (!category){
            return thisIter
        }
    }),(thisLink, index) => {
        return (
            <a key={index} href={thisLink.url} id={`${thisLink.category}-${thisLink.label}`} target="_blank" rel="noopener noreferrer"><Card key={index} bg="dark" text="white">
                
                <Card.Body>
                    { thisLink.picurl && <Card.Img variant="top" src={thisLink.picurl} />}
                    <Card.Title>{thisLink.label}</Card.Title>
                    <small><i>{thisLink.category}</i></small>
                    <Card.Text>{thisLink.desc}</Card.Text>
                </Card.Body>
                <Card.Footer className="text-right"><Button variant="light" size="sm">Website</Button></Card.Footer>
            </Card>
            </a>
        )
        // <tr key={`link-${index}`}>
        //     <td><a style={{color:'white'}} href={thisLink.url}>{thisLink.label}</a></td>
        //     <td >{thisLink.category}</td>
        //     <td>{thisLink.desc}</td>
        // </tr>
        
    } )
}

const LinkPage = ({match:{params},fetchLinks}) => {
    const [linkData, setLinkData] = useState([])
    const [userStateProv,setUserStateProv] = useState(params.stateprov)
    const [strMessage, setStrMessage] = useState(null)
    const [linkLoading, setLinkLoading] = useState(true)
    const [category,setCategory] = useState(params.category)

    useEffect(() => {
        fetchLinks({},setLinkData,setStrMessage,setLinkLoading)
    }, [category,userStateProv])
    
    return <>
    <Header />
        
        <Row className="bg-dark-j text-light" style={{height:'8vh'}}>
            <Container>
            <Col>
                <h1 className="h1" style={{textTransform:'uppercase'}}>Links</h1>
            </Col>
            </Container>
        </Row>
        <Container>
        <Row className="mt-3 landing-section-body">
            
            <Col lg={3} md={4} sm={12}>
                <Card bg="dark" text="light" className="mb-1">
                    <Card.Body className="d-flex" style={{flexDirection:'column'}}>
                        <Card.Title>Filter by Category</Card.Title>
                        <LinkContainer to="/links/advocacy"><Button 
                            size="sm" 
                            className="mb-1"
                            onClick={() => {
                                setCategory('advocacy')
                                
                            }}
                        >Advocacy</Button></LinkContainer>
                        <LinkContainer to="/links/entertainment"><Button 
                            size="sm" 
                            className="mb-1"
                            onClick={() => {
                                setCategory('entertainment')
                                
                            }}
                        >Entertainment</Button></LinkContainer>
                        <LinkContainer to="/links/Music"><Button 
                            size="sm" 
                            className="mb-1"
                            onClick={() => {
                                setCategory('music')
                                
                            }}
                        >Music</Button></LinkContainer>
                        <LinkContainer to="/links/press"><Button 
                            size="sm" 
                            className="mb-1"
                            onClick={() => {
                                setCategory('press')
                                
                            }}
                        >Press</Button></LinkContainer>
                        <LinkContainer to="/links/fashion"><Button 
                            size="sm" 
                            className="mb-1"
                            onClick={() => {
                                setCategory('fashion')
                                
                            }}
                        >Fashion</Button></LinkContainer>
                        <LinkContainer to="/links/social"><Button 
                            size="sm" 
                            className="mb-1"
                            onClick={() => {
                                setCategory('social')
                                
                            }}
                        >Social</Button></LinkContainer>
                    </Card.Body>
                </Card>
                {/* <Card bg="dark" text="light">
                    <Card.Body>
                        <Card.Text>Select your state to see information tailored to you.</Card.Text>
                        <FormControl as="select">
                            <option>Alaska</option>
                            <option>Alabama</option>
                        </FormControl>
                    </Card.Body>
                </Card> */}
            </Col>
            <Col style={{maxHeight:'75vh',overflowY:'auto'}}>
                { linkLoading ? 
                <h2>Loading</h2>    :
                <>
                <CardColumns>
                    {renderLinks({linkData,category,userStateProv})}
                </CardColumns>
                </>
            }
                
                
            </Col>
            
        </Row>
        </Container>
    <Footer />
    </>
    
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps
    }
}

export default connect(mapStateToProps,{fetchLinks})(LinkPage)