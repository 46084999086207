import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import KJ from './KJ'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'
import ReactPlayer from 'react-player/youtube'
import Container from 'react-bootstrap/Container'
import ReactGa from 'react-ga4'
import MusicListenButton from './MusicListenButton'
import Button from 'react-bootstrap/Button'

import songs from './songs'
import _ from 'lodash'
import Shows from './Shows'
import BookingButton from './BookingButton'

const Booking = (props) => {
    const randomNum = Math.floor(_.random(songs.length - 1))
    const song = songs[randomNum]
    return (
        <>
        <Header />
        <Row fluid="true" style={{ minHeight:'83vh' }}>
            <Container>
            <Row>
            <Col>
                <h1 className="text-center">
                <span className="text-glow-neon all-caps" id="bookingTitle">Say Gay! at your pride event</span>
                </h1>
            </Col>
            </Row>
            <Row className="p-3 mb-2" style={{background:'rgba(255,255,255,0.5)'}}>
                <Col style={{display:'flex',justifyContent:'center'}}>
                    <ReactPlayer 
                        url={'https://youtube.com/playlist?list=PLEsmJ45uILbWjpwZxzxBSalcY0zDc9JSG&si=zhu0Y4V3XFdnmAbS'}
                        controls="true"
                        className="mt-1"
                    />
                    
                </Col>
                <Col className="text-center p-2 mb-2">
                    <BookingButton propColor="dark" />
                    <p>info@johnlevengood</p>
                    <p className="text-left" style={{fontSize:'1rem'}}>Reach out to John directly via email at <a href="mailto:info@johnlevengood.com">info@johnlevengood.com</a> .</p>
                    <h2 className="text-left" style={{fontSize:'1.2rem'}}>Quick Info:</h2>
                    <p className="text-left">
                        <ul style={{fontSize:'1.1rem'}}>
                            <li>Upbeat pride anthems</li>
                            <li>Inspires advocacy for equality</li>
                            <li>Flexible booking fees</li>
                        </ul>
                    </p>
                    
                </Col>
            </Row>
            <Row className="p-3 mb-2" style={{background:'rgba(255,255,255,0.5)'}}>
                <Col>
                <p className="text-left" >
                John Levengood is more than a singer: he is a queer icon with a vision, a popstar with a purpose. His catchy hooks, witty lyrics, and elaborate live performances are a tribute to all things queer. He wants his music to empower others to be themselves, embrace their identity and most importantly to use their voice to make an impact.
                    </p>
                </Col>
            </Row>
            <Row>
                {/* <Col >
                    <KJ />
                </Col> */}
                <Col style={{display:'flex',justifyContent:'center',alignItems:'flex-start'}}>
                    <Row >
                        <MusicListenButton site="instagram" url="https://instagram.com/johnlevengood" />
                        <MusicListenButton site="apple"/>
                        <MusicListenButton site="spotify"/>
                        <MusicListenButton site="pandora" />
                        <MusicListenButton site="amazon" />
                        <MusicListenButton site="tidal" />
                        <MusicListenButton site="youtube" />
                    </Row>
                </Col>
                <Col sm={12} md={12} lg={8} className="p-4"><Shows /></Col>
            </Row>
            </Container>
        </Row>
        
        <Footer />
        </>
    )
}

export default Booking