import React from 'react'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ReactGa from 'react-ga4'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import ReactPlayer from 'react-player/youtube'

import songs from './songs'
import sites from './sites'
import MusicListenButton from './MusicListenButton'
import Links from './Links'
import _ from 'lodash'
import Footer from '../Landing/Footer'
import EmailSignUp from '../Marketing/EmailSignup'
import BookingButton from './BookingButton'
import { LinkContainer } from 'react-router-bootstrap'
import Helmet from 'react-helmet'


const musicButtons = (song) => {
    return sites.map((site,index) => {
        let linkTo = site.url
        let borderClass = ""
        if (song[site.name.toLowerCase()]) {
            linkTo = song[site.name.toLowerCase()]
        }
        if(index > 0){
            borderClass="site-border"
        }
        return (

            <Row key={index} className={borderClass} xs={2}>
                <Col>
                    <MusicListenButton site={site.name.toLowerCase()} url={linkTo}/>
                </Col>
                <Col style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Row>
                        <Col>
                            <a
                                href={linkTo}
                                target="_blank" rel="noopener noreferrer"
                                key={index}
                            ><Button variant="outline-light" size="" block key={index}><b>PLAY</b></Button></a>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
        )
    })
}

const Song = (props) => {
    const songId = props.match.params.song.toLowerCase()
    const song = songs.find(track => track.id.toLowerCase() === songId)
    if (!song) {
        return <Links />
    }
    let youTubeURLs = _.map(_.filter(songs, (filtSong)=> filtSong.id !== songId), (thisSong,index) => {
        if(thisSong.youtube && thisSong.id !== songId) {
            return thisSong.youtube
        }
        
    })
    const meta = {
        link: 'https://www.JohnLevengood.com/music/werkethic',
        desc: "Queer pop music by John Levengood",
        title: `${song.title} by John Levengood (${song.year})`
    }
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{meta.title}</title>
            <link rel="canonical" href={meta.link} />
            <meta name="description" content="Queer pop music by John Levengood" />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={meta.link} />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta property="og:image" content="https://johnlevengood.com/johnlevengoodsiteheader.png" />

            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={meta.link} />
            <meta property="twitter:title" content={meta.title} />
            <meta property="twitter:description" content={meta.desc} />
            <meta property="twitter:image" content="https://johnlevengood.com/johnlevengoodsiteheader.png" />
        </Helmet>
        <Row style={{minHeight:'100vh'}}>
            <Col lg={7} sm={12} style={{display:'flex', alignItems:'center',boxShadow:'0px 0px 20px rgba(0,0,0,.5)'}}>
                    <ReactPlayer 
                        url={[song.youtube, 'https://youtu.be/dUcwNc-VDOU', ...youTubeURLs]}
                        controls={true}
                        className="mt-1"
                        width="100%"
                        height="100%"
                    />
                
                
            </Col>
            <Col lg={5} sm={12} style={{backgroundColor:'black'}} >
                <Row className="pt-2 pl-5 pr-5" style={{display:'flex', height:'80%',alignItems:'center'}}>
                    <Col >
                        <Row>
                            <Col className="text-strong" >JOHN LEVENGOOD</Col>
                        </Row>
                        <Row>
                            <Col >
                                <h1 className="h4 text-strong" >{song.title}</h1>
                                
                            </Col>
                        </Row>
                        <Row >
                            <Col >{musicButtons(song)}</Col>
                        </Row>
                        
                    </Col>
                </Row>
                <Row className="mt-5 text-white text-center">
                    <Col>
                        
                    </Col>
                </Row>
                
                
            </Col>
        </Row>
        <Row lg={2} md={1} sm={1} xs={1}>
            <Col><EmailSignUp /></Col>
            <Col className="text-center p-2 mb-2">
                <BookingButton propColor="dark" />
                <p>info@johnlevengood</p>
                <LinkContainer to="/shows" as={Button} >
                    <Button size="lg" variant="dark" style={{background:'black'}} >Shows</Button>
                </LinkContainer><br />
                <a
                    id="instagram"
                    href='https://instagram.com/johnlevengood'
                    target="_blank" rel="noopener noreferrer"
                >
                    <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">Instagram</Button>
                </a>
                <a
                    id="instagram"
                    href='https://www.tiktok.com/@johnlevengood'
                    target="_blank" rel="noopener noreferrer"
                >
                    <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">TikTok</Button>
                </a>
                
            </Col>
        </Row>
        <Footer />
        </>
    )
}

export default Song