import React from 'react'
import ReactGa from 'react-ga4'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
const sites = {
    spotify: 'https://open.spotify.com/artist/64tEylbYN3xfXlxne6d78A?si=yFCSZf6oRZudZt56KP5pWA',
    apple: 'https://music.apple.com/us/artist/john-levengood/542969359?itsct=music_box&itscg=30200&app=music&ls=1',
    pandora: 'https://pandora.app.link/0xmVVuulP8',
    amazon: 'https://music.amazon.com/artists/B008JPPD82?ref=dm_sh_314d-ab33-dmcp-d86d-13060&musicTerritory=US&marketplaceId=ATVPDKIKX0DER',
    tidal: 'https://listen.tidal.com/artist/5688826',
    youtube: 'https://music.youtube.com/channel/UCu35Mt3SQ-ou7S9NB0BnAjg'
}

const MusicListenButton = ({site, url}) => {
    let link = sites[site]
    if (!link) {
        return (
            <Col style={{display:'flex',justifyContent:'center'}}><a
                id={site}
                href={url}
                target="_blank" rel="noopener noreferrer"
                className="site-logo mb-2"
            >
                <Button size="lg" variant="dark" style={{background:'black'}} className="music-button text-glow-neon">Instagram</Button>
            </a></Col>
        )
    }

    
    let btnClass = "music-button"
    if (url) {
        link = url
        btnClass = "site-logo"
        return (
            <div id={site + '-button'} className={btnClass} style={{width:"100%"}} />
        )
    }
    return (
        <Col style={{display:'flex',justifyContent:'center'}}>
        <a id={site} href={link} target="_blank" rel="noopener noreferrer"><div id={site + '-button'} className={btnClass} /></a>
        </Col>
        
    )
}

export default MusicListenButton