import comebackArt from '../../assets/img/albumart/comeback.jpg'
import singArt from '../../assets/img/albumart/sing.png'
import rightnowArt from '../../assets/img/albumart/rightnow.jpg'
import saygayart from '../../assets/img/albumart/saygaysmall.jpg'

const songs = [
    {
        title: "Werk Ethic",
        year:'2023',
        id: 'werkethic',
        youtube: 'https://youtu.be/8wHHbdo-sUM'
    },
    {
        title: "Say Gay!",
        year:'2022',
        id:'saygay',
        art: saygayart,
        youtube: 'https://www.youtube.com/watch?v=X2kRyxPf200'
    },
    {
        title: "Right Now (Acoustic)",
        year: '2022',
        youtube: 'https://youtu.be/YSX10Zv8taE'
    },
    {
        title: "Comeback (We're Gonna)",
        year:'2020',
        id:'comeback',
        art: comebackArt,
        spotify:'https://open.spotify.com/track/7AB6ueDi7MLzKhEOsexArc',
        youtube: 'https://www.youtube.com/watch?v=FznbGAWbfdM',
        pandora: 'https://pandora.app.link/1026DxC899'
    },
    { 
        title: 'Sing', 
        year: '2018', 
        id:'sing',
        art: singArt,
        youtube: 'https://www.youtube.com/watch?v=iT255GAWr8s',
        spotify:'https://open.spotify.com/track/4iWXt9SeU4RJWf9t97pjB6',
        pandora:'https://pandora.app.link/UJ9iFQvlP8'
    },
    { 
        title: 'Right Now', 
        year: '2022', 
        id:'rightnow',
        art:rightnowArt,
        spotify:'https://open.spotify.com/track/2UlnaJZCcKlD2FodlGpj4j?si=e4498da2491e47ba',
        pandora: 'https://pandora.app.link/h3DOUnb8bob',
        youtube: 'https://youtu.be/lZ_0CVCo_8w'
    },
    { 
        title: 'Reach', 
        year: '2018', 
        id:'reach',
        art:'https://c60b8g.bn.files.1drv.com/y4mgVB0laHkMukLmJ-nL2uisc65AkrmrG8m0FOtaN9qsCtZOrU03H0hdJ-B3Tl-LLCPVMumm_BpItEOLVgMJizLIFt58zB_DcOpIdBkhmd19JddNBf6TcOUk7NijOvFQ251rzqGl_W_dE4okMI58Sr8sW4e8Xhlb-LflgxOUrzm31hnIn7h38elXWrNLmAPSxAkzBtphPakkVT9-LOcTHQuig?width=960&height=960&cropmode=none',
        youtube: 'https://www.youtube.com/watch?v=HqvIrUwFQe4',
        spotify:'https://open.spotify.com/track/1qMrcGPCxM11aQ7YoquxHe',
        pandora:'https://pandora.app.link/BJK6oQvlP8'
    }
]

export default songs