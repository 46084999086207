import React from 'react'
import {connect} from 'react-redux'
import * as actions from '../../actions'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { LinkContainer } from 'react-router-bootstrap'
import SpotifyFollow from '../Music/SpotifyFollow'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import EmailSignUp from '../Marketing/EmailSignup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import KJ from '../Music/KJ'

const Footer = (props) => {
    return (
        <>

        <Navbar expand="lg" sticky="bottom" bg="dark" defaultExpanded variant="dark" style={{minHeight:'10vh'}}>
            <Container>
            
            <Navbar.Collapse id="responsive-navbar-nav" style={{ textAlign:'center' }}>
                <LinkContainer to="/"><Navbar.Brand>John Levengood</Navbar.Brand></LinkContainer>
                <Nav className="mr-auto">
                    
                    
                </Nav>
                <Nav className="mr-auto">
                    <LinkContainer to="/links"><Nav.Link>Links</Nav.Link></LinkContainer>
                </Nav>
                <Nav.Item className="white-text">
                    Copyright © 2023 Levengood Creative, Levengood LLC - All Rights Reserved.
                </Nav.Item>
                <Nav.Item>
                    <div
                        className='white-text cursor-point'
                        onClick={(e) => {
                            e.preventDefault()
                            props.togglePrivacy(true)
                        }}
                    ><u>Terms of Use</u></div>
                    </Nav.Item>
            </Navbar.Collapse>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" hidden={true} />
            
            </Container>
        </Navbar>
        </>
    )
}

export default connect(null, actions)(Footer)