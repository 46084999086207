import React from 'react'

import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'
import ReactGa from 'react-ga4'
import BookingButton from '../Music/BookingButton'

const HeaderNav = ({setShowMenu}) => {
    return (
        <>
            <Nav className="mr-auto">
                    
                <Nav.Link className="text-light" href="/links/Music" onClick={()=> setShowMenu(false)}>Music</Nav.Link>
                {/* <LinkContainer to="/tech"><Nav.Link>Technology</Nav.Link></LinkContainer> */}
                <LinkContainer to="/about"><Nav.Link className="text-light">Bio</Nav.Link></LinkContainer>
                <LinkContainer to="/shows"><Nav.Link className="text-light">Shows</Nav.Link></LinkContainer>
                <LinkContainer to="/links"><Nav.Link className="text-light">Links</Nav.Link></LinkContainer>
                <LinkContainer to="/links/fashion"><Nav.Link className="text-light">Style</Nav.Link></LinkContainer>
                <Nav.Link
                    href="https://johnlevengood.myshopify.com/"
                    className="text-light"
                    target="_blank" rel="noopener noreferal"
                    onClick={()=> setShowMenu(false)}
                >Merch</Nav.Link>
                {/* <LinkContainer to="/music"><Nav.Link>Music</Nav.Link></LinkContainer> */}
                    {/* <Nav.Link
                            href="https://www.instagram.com/johnlevengood/"
                            target="_blank" rel="noopener noreferal"
                            onClick={()=> setShowMenu(false)}
                    >Instagram</Nav.Link>
                    <Nav.Link
                            href="https://www.tiktok.com/@johnlevengood"
                            target="_blank" rel="noopener noreferal"
                            onClick={()=> setShowMenu(false)}
                    >TikTok</Nav.Link>
                    <Nav.Link
                            href="https://www.facebook.com/johnlevengood"
                            target="_blank" rel="noopener noreferal"
                            onClick={()=> setShowMenu(false)}
                    >Facebook</Nav.Link>
                    <Nav.Link
                            href="https://twitter.com/johnlevengood"
                            target="_blank" rel="noopener noreferal"
                            onClick={()=> setShowMenu(false)}
                    >Twitter</Nav.Link> */}
                <LinkContainer to="/karaoke"><Nav.Link className="text-light">Karaoke</Nav.Link></LinkContainer>
                    
            </Nav>
            <Nav>
                <LinkContainer to="/booking"><Button>Booking</Button></LinkContainer>
            </Nav>
        </>
    )
}

export default HeaderNav