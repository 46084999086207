import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import ReactPlayer from 'react-player/youtube'

import FamilyCarousel from './FamilyCarousel'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'



const Family = (props) => {
    return (
        <>
        <Header />
        <Row fluid="true" style={{ margin: "0%", padding:'2% 2% 2% 2%', width:'100%' }}>
            <Col lg={4} md={6} sm={2} style={{ display: 'flex', flexDirection: 'column', justifyContent:'space-around', alignItems: 'center'}}>
                <Card style={{ width:'100%' }}>
                    <ReactPlayer 
                        url="https://www.youtube.com/watch?v=4LGxWDxH6Dk&list=PLEsmJ45uILbU1Z3aAGtQPF2Bfs7yht68r&index=2"
                        width="100%"
                        controls={true}
                    />
                    <Card.Body>
                        <Card.Text>
                            It was just Jim (dad), Sandy (mom), Savannah (sister), and John until 2016. That's when Amber (and my nieces and nephews) found us after 37 years of searching her dad.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card style={{ width:'100%' }}>
                    <ReactPlayer 
                        url="https://www.youtube.com/watch?v=H1e7reJlwGk&list=PLEsmJ45uILbU1Z3aAGtQPF2Bfs7yht68r&index=5"
                        width="100%"
                        controls={true}
                    />
                    <Card.Body>
                        <Card.Text>
                            Listen to Amber's story through the eyes of her sister, Rena. It was hard growing up without a dad, so we're making up for lost time now.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={2}></Col>
            <Col lg={6} md={5} sm={1} style={{ display: 'flex', height: '80vh', flexDirection: 'column', justifyContent:'flex-end', alignContent:'center' }}>
                    <h5 style={{ color: 'white', textAlign:'center'}}>Livin' It Up With</h5>
                    <h1 style={{ color: 'white', textAlign:'center'}}>The Levengoods</h1>
                <FamilyCarousel />
            </Col>
        </Row>
        <Footer />
        </>
    )
}

export default Family