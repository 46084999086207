import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import { createStore , applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import ReactGa from 'react-ga4'

import App from './components/App'
import reducers from './reducers'

const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, {}, composeEnhancers(
    applyMiddleware(reduxThunk)
))

ReactGa.initialize('G-QJKCVQQ6N8')

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.querySelector('#root')
)