import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import qrcode from './karaokefreddiestv.png'
import Image from 'react-bootstrap/Image'

const KaraokeQR = (props) => {
    return (
        <Container fluid style={{height:'100vh', width:'100vw'}} className="text-center">
            <Row>
                <Col>
                    <p style={{fontSize:'4rem'}} className="text-strong">Digital Karaoke Songbook</p>                    
                </Col>
            </Row>
            <Row >
                <Col></Col>
                <Col>
                    <Container>
                    <Image fluid thumbnail rounded src={qrcode} style={{width:'100%'}}/>
                    
                    </Container>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default KaraokeQR