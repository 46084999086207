import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container'

const EmailSignUp = () => {
    return (
        <Col className="p-4" >
            <Container className="bg-dark-j-faded p-3">
            <Row>
                <Col>
                    <p className="h2">Keep in Touch</p>
                    <p className="">Get email updates from me about music, performances, and LGBTQ+ activism opportunities.</p></Col>
            </Row>
            <Row><Col>
                <Form action="https://squareup.com/outreach/bzsnpi/subscribe" method="POST" target="_blank">
                    <Form.Row>
                        <Col>
                            <Form.Control size="lg" type="email" name="email_address" placeholder="Your Email Address"  />
                            <Form.Control type="hidden" name="embed" value="true" />
                        </Col>
                        <Col>
                            <Button size="lg" type="submit">Join Now</Button>
                        </Col>
                    </Form.Row>
                    
                </Form>
            </Col></Row>
            </Container>
        </Col>
    )
}

export default EmailSignUp