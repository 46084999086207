import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import KJ from './KJ'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'
import ReactPlayer from 'react-player/youtube'
import Container from 'react-bootstrap/Container'
import ReactGa from 'react-ga4'
import MusicListenButton from './MusicListenButton'
import Button from 'react-bootstrap/Button'

import songs from './songs'
import _ from 'lodash'
import Shows from './Shows'
import BookingButton from './BookingButton'
import EmailSignUp from '../Marketing/EmailSignup'

const SecretPreview = (props) => {
    return (
        <>
        <Header />
        <Row fluid="true" style={{ minHeight:'83vh' }}>
            <Container>
                <Row className="p-3 mb-2" >
                    <Col style={{display:'flex',justifyContent:'center'}}>
                        <ReactPlayer 
                            url={['https://youtu.be/YUc-qYH_1N4','https://youtu.be/dUcwNc-VDOU','https://youtu.be/Vea9HA2lf5Q','https://youtu.be/z-ydwXER6YA','https://youtu.be/kr8IUMh5icE','https://youtu.be/qOIdNDe6H64']}
                            controls="true"
                            className="mt-1"
                        />
                        
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <BookingButton propColor="dark" />
                        <p className="h3">info@johnlevengood.com</p>
                    </Col>
                </Row>
                <Row className="landing-section-body" style={{minHeight:'10vh'}}>
                    <Container fluid>
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col lg={12} md={12} xl={12} sm={12} xs={12} >
                            {/* <iframe width="100%" height="800px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAANFbVq1UMTc3Tk5FMjQxMlpNREU0VlhSUjlQS1VVOS4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: "none", maxWidth:"100%", maxHeight:"100vh"}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> */}
                            <EmailSignUp />
                            </Col>
                        </Row>
                    
                    </Container>
                </Row>
                <Row>
                {/* <Col >
                    <KJ />
                </Col> */}
                <Col style={{display:'flex',justifyContent:'center',alignItems:'flex-start'}}>
                    <Row >
                        <MusicListenButton site="instagram" url="https://instagram.com/johnlevengood" />
                        <MusicListenButton site="apple"/>
                        <MusicListenButton site="spotify"/>
                        <MusicListenButton site="pandora" />
                        <MusicListenButton site="amazon" />
                        <MusicListenButton site="tidal" />
                        <MusicListenButton site="youtube" />
                    </Row>
                </Col>
            </Row>
            </Container>
        </Row>
        
        <Footer />
        </>
    )
}

export default SecretPreview