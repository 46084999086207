import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../../actions'

import Modal from 'react-bootstrap/Modal'

const TermsOfUse = (props) => {
    const modal = props.modal.priv
    return (
        <Modal
            show={modal}
            onHide={props.togglePrivacy}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Terms of Use
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight:'50vh', display: 'flex', flexWrap:'wrap', justifyContent:'space-around', overflowY:'auto' }}>
            <iframe title="terms" src="https://onedrive.live.com/embed?cid=7FE00645476F247C&resid=7FE00645476F247C%2123117&authkey=AOgx24TejaHBTPs&em=2" width="476" height="288" frameBorder="0" scrolling="no"></iframe>
            </Modal.Body>

        </Modal>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, actions)(TermsOfUse)