import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Header from '../Landing/Header'
import Footer from '../Landing/Footer'
import AboutPhoto from '../../assets/img/pride2022.jpg'
import AboutPhoto2 from '../../assets/img/johnlevengood2023capitalpride.jpg'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import ReactGa from 'react-ga4'
import ReactPlayer from 'react-player/youtube'
import BookingButton from '../Music/BookingButton'

const About = (props) => {
    return (
        <>
        <Header />
        <Row id="music" className="bg-dark-j text-light landing-section-header" >
            <Container className="d-flex align-items-end flex-wrap ">
                <Col>
                    <h1 style={{fontSize:'6rem', textTransform:'uppercase'}} className="text-right">About John</h1>
                </Col>
            </Container>
            
        </Row>
        <Row className="landing-section-body">
            <Col className="text-light">
                <Container style={{padding: '25px',fontSize:'1.1rem'}}>
                    <Row className="text-center">
                        <Col><BookingButton propColor="primary" subject="We want you on our stage" /></Col>
                    </Row>
                    
                <h2>Bio</h2>
                <p>John Levengood is more than a singer: he is a queer icon with a vision, a popstar with a purpose. His catchy hooks, witty and provocative lyrics, and elaborate live performances are a tribute to all things queer. He wants his music to empower others to be themselves, embrace their identity and most importantly to use their voice to make an impact.</p>
                <p>His journey to stardom took flight in a campy LGBTQ+ bar near Washington, DC, where he hosted karaoke nights, practiced his music, and learned from the dazzling drag queens as their DJ. In 2022, John rocked the stage at Capital Pride in the Nation's Capital, performing his anthem "Say Gay!" for the first time live. This was only his second time attending a pride festival. Since then, he has wowed audiences at over 20 pride festivals across the country, from Newark Pride at the New Jersey Performing Arts Center to the main stage at Charlotte Pride in North Carolina. He is on his way to becoming a national queer sensation.</p>
                <ReactPlayer 
                        url={'https://youtube.com/playlist?list=PLEsmJ45uILbWjpwZxzxBSalcY0zDc9JSG&si=zhu0Y4V3XFdnmAbS'}
                        controls="true"
                        className="mt-3 mb-3"
                    />
                <p>His musical influences include Shania Twain and Britney Spears, two divas who shaped his childhood in the conservative Shenandoah Valley of Virginia. Although everyone else had been making this conclusion for most of John's life, it was not until 2019 that he came out of the closet. This liberating (and scary) moment gave him the courage to be himself and a vision to make music for his community. </p>
                <p>In 2013, John faced a major setback when he auditioned for the third season of reality-show, The X Factor USA. He sang his heart out for Simon Cowell, Kelly Rowland, and Demi Lovato, but the judges unanimously rejected him. John felt crushed and humiliated in front of a 4,000+ member audience; he thought his dream of making music was over. But he didn't give up: he used that pain as fuel to ignite his passion and prove them wrong.</p>
                <p>John first became involved in politics at the young age of 14 when he began an internship with the Senate of Virginia as a Page. During this time he got to see the state's legislative process up close and personal. This also led to John's first feature in a newspaper. The same year, John utilized his newly acquired legal prowess to cite the American's with Disabilities Act to pressure his parent's HOA to install handicap parking spaces at the community pool for his blind grandfather.</p>
                <p>John's passion for music began in his childhood. He discovered his voice in middle school, when his teacher, Linda Ervin, urged him to try out for a solo in the choir. He loved the thrill of performing and never looked back. He took every opportunity to showcase his talent in high school musicals.</p>
                <p>He recorded his debut album, Confident (2012), in his senior year. He admits that he has grown as a songwriter since then, but he keeps the album online as a testament to his journey. The same year, he moved to Los Angeles for college, dreaming of becoming a star. He didn't find fame, but he found himself and learned to appreciate diversity. He collaborated with producer Peter Duff on two albums, Storm (2013) and Reach (2014).</p>
                </Container>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="text-center">
                <Row>
                    <Image fluid src={AboutPhoto2} alt="John Levengood singing with headset and backupdancer" />
                </Row>
                <Row id="pressKit" className="bg-dark-j text-light landing-section-header">
                    <Container className="d-flex align-items-end flex-wrap ">
                        <Col lg={3} sm={6} xs={12}>
                            <h2 style={{fontSize:'6rem', textTransform:'uppercase'}} className="">Press</h2>
                        </Col>
                        
                        
                    </Container>
                </Row>
                <Row className="bg-dark-j text-light" lg={2} md={2} sm={1} xs={1}>
                    <Col className="p-5 text-left">
                        <p style={{fontSize:'1.2rem'}}><a id="pressWashBlade" rel="noreferrer" href="https://www.washingtonblade.com/2022/05/25/john-levengood-releases-anthem-say-gay-to-protest-discrimination/">Washington Blade: John Levengood releases anthem “Say Gay!” to protest discrimination</a></p>
                    </Col>
                    <Col className="p-5 text-left">
                        <p style={{fontSize:'1.2rem'}}><a id="pressQcNerve" rel="noreferrer" href="https://qcnerve.com/charlotte-pride-music-lineup/" >Charlotte Pride’s 2023 Main Stage Music Lineup</a></p>
                    </Col>
                    <Col className="text-center">
                        <iframe src='https://onedrive.live.com/embed?cid=7FE00645476F247C&resid=7FE00645476F247C%2138642&authkey=AElCDWHsMhGLDrQ' width="165" height="128" frameborder="0" scrolling="no"></iframe>
                    </Col>
                </Row>
            </Col>
        </Row>
    
        <Footer />
        </>
    )
}

export default About