import React, { useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import _ from 'lodash'

import { connect } from 'react-redux'
import {fetchSongs, fetchKJs, fetchRandom} from '../../actions'
import Header from '../Landing/Header'
import MusicBanner from './MusicBanner'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import KJHosts from './KJHosts'
import ReactGa from 'react-ga4'
import Helmet from 'react-helmet'

const renderResultList = (results,setShowModal,fetchRandom,setResults) => {

    return (
        <Container style={{height:'60vh', overflowY:'auto'}}>
            <Row style={{height:'7%'}}>
                <Col>
                    <h1 className="h3 text-center text-strong text-glow-pink" >Karaoke Songbook</h1>
                </Col>
            </Row>
            <Row style={{maxHeight:'13%'}} >
                <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Button size="lg" block
                        onClick={() => {
                            setShowModal(true)
                        }} 
                    className="mt-1 mb-1 bg-emph"><small>Song Search</small></Button>
                </Col>
                <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Button className="mt-1 mb-1 bg-emph" size="lg" block onClick={() => {
                        fetchRandom(setResults)
                    }}
                    ><small>Random Songs</small></Button>
                </Col>
            </Row>
            { results.length === 0 ? <p className="h3">NO RESULTS FOUND</p> :
            <Row id="karaokeResults" style={{height:'80%', overflowY:'auto'}}>
                <Col>
                    <Card text="white" style={{backgroundColor:'rgba(0,0,25,0.5)'}}>
                        <Table size="sm" striped style={{color:'white'}} >
                            <thead>
                                <tr>
                                    <th>Song</th>
                                    <th>Artist</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                _.map(results, (result) => {
                                    return <tr key={result._id}>
                                        <td>{result.title}</td>
                                        <td>{result.artist.replace('|',',')}</td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row> }
        </Container>
        
    )
    
        
}

const KjTipButton = (fetchKJs,setHosts,kjName,setShowHosts) => {
    
    if(kjName){
        return <Button className="mt-1 mb-1 bg-emph" size="lg"  block onClick={() => {
            fetchKJs(setHosts)
            setShowHosts(true)
        }} style={{textTransform: 'uppercase'}}
        ><small>$ TIP {kjName} $</small></Button>
    } else {
        return <Button className="mt-1 mb-1 bg-emph" size="lg" block onClick={() => {
            fetchKJs(setHosts)
            setShowHosts(true)
        }}
        ><small>Tip the KJ  &#129297;</small></Button>
    }
}

const defaultLoad = (setShowModal,fetchRandom,setResults) => {
    return (
        <Container style={{overflowY:'auto'}}>
            <Row>
                <Col>
                    <h1 className="h1 text-center text-strong text-glow-pink" >Karaoke Songbook</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button size="lg" block
                        onClick={() => {
                            setShowModal(true)
                        }} 
                    className="mt-1 mb-1 bg-emph"><small>Song Search</small></Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="mt-1 mb-1 bg-emph" size="lg" block onClick={() => {
                        fetchRandom(setResults)
                    }}
                    ><small>Random Songs</small></Button>
                </Col>
            </Row>

        </Container>
    )
}


const Karaoke = ({fetchSongs, fetchKJs, fetchRandom,match}) => {
    
    const [results, setResults] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [showHosts, setShowHosts] = useState(false)
    const [hosts, setHosts] = useState([])
    const meta = {
        link: 'https://www.JohnLevengood.com/karaoke',
        desc: "Search for songs to sing during karaoke night at Freddie's Beach Bar in Arlington, VA. Search created by John Levengood",
        title: "Karaoke Night at Freddie's Beach Bar"
    }
    let kjName = match.params.kjName
    if (kjName) {
        kjName = kjName.toLowerCase()
    }
    
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{meta.title}</title>
            <link rel="canonical" href={meta.link} />
            <meta name="description" content="Search for songs to sing during karaoke night at Freddie's Beach Bar in Arlington, VA. Search created by John Levengood." />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={meta.link} />
            <meta property="og:title" content={meta.title} />
            <meta property="og:description" content={meta.desc} />
            <meta property="og:image" content="https://johnlevengood.com/johnlevengoodsiteheader.jpg" />

            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={meta.link} />
            <meta property="twitter:title" content={meta.title} />
            <meta property="twitter:description" content={meta.desc} />
            <meta property="twitter:image" content="https://johnlevengood.com/johnlevengoodsiteheader.jpg" />
        </Helmet>
        <Header />
        
            { !results ? 
            defaultLoad(setShowModal,fetchRandom,setResults) :
            renderResultList(results,setShowModal,fetchRandom,setResults)}


        {/* TIP THE KJ ROW */}
        <Container style={{height:'10vh', overflowY:'auto'}}>
            <Row>
                <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    {KjTipButton(fetchKJs,setHosts,kjName,setShowHosts)}
                </Col>
            </Row>
        </Container>
        <MusicBanner />
        <Modal show={showModal} centered onHide={() => {
            setShowModal(false)
        }} >
            <Modal.Header closeButton className="text-dark">
                    <h3 style={{textTransform:'uppercase'}}>Search for Songs</h3>
            </Modal.Header>
            <Modal.Body className="text-dark">
                <Row>
                    <Col>
                        <Form id="karaokeSearch" noValidate onSubmit={async (e)=> {
                            e.preventDefault()
                            setShowModal(false)
                            const artistTerm = document.getElementById('artistTerm').value
                            const songTerm = document.getElementById('songTerm').value
                            fetchSongs({artist: artistTerm, song: songTerm}, setResults)
                            
                        }}>
                            <Row>
                                <Form.Group as={Col} lg={12} sm={12}>
                                    <Form.Label>Artist</Form.Label>
                                    <Form.Control type="text" id="artistTerm" 
                                        autoComplete="false"
                                    />
                                    
                                </Form.Group>
                                <Form.Group as={Col} lg={12} sm={12}>  
                                    <Form.Label>Song</Form.Label>                              
                                    <Form.Control 
                                        type="text"
                                        id="songTerm"
                                        autoComplete="false"
                                    />
                                    
                                </Form.Group>
                                <Col lg={4} sm={12} className="text-center">
                                    <Button variant="dark" size="lg" block type="submit">Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        <Modal show={showHosts} centered onHide={() => {
            setShowHosts(false)
        }}
        >
            <Modal.Header closeButton>
                    <h3 style={{textTransform:'uppercase'}}>Karaoke Hosts</h3>
            </Modal.Header>
            <Modal.Body>
                
                    <KJHosts fetchKJs={fetchKJs} kjs={hosts} selectedKJ={kjName}/>
            </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps
    }
}

export default connect(mapStateToProps, {fetchSongs, fetchKJs, fetchRandom})(Karaoke)